import React from 'react';
import '../theme.js';
import { Button, Tag } from 'antd';
import Mollie from '../service/mollie';
import Logo from '../components/logo.js';
import Seo from '../components/seo';
import { CheckOutlined } from '@ant-design/icons';

class Preview extends React.Component {
    state = {
        order: null,
        delay: 2500
    };

    interval = null;

    componentDidMount() {
        this.handleWindowSizeChange();
        window.addEventListener('resize', this.handleWindowSizeChange);

        if (window.location.hash) {
            localStorage.setItem('mollie_checkoutid', window.location.hash.substring(1));
        }

        let x = function () {
            let mollie = new Mollie();
            mollie.getOrder().then(
                function (data) {
                    let delay = this.state.delay + 2500;
                    if (data.status === 'created' || data.status === 'pending') {
                        delay = 2500;
                    }
                    this.setState({ order: data, delay: delay });
                }.bind(this)
            );
        }.bind(this);
        // Start and set interval
        x();
        this.interval = setInterval(x, this.state.delay);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    handleWindowSizeChange = () => {
        const isMobile = window.innerWidth <= 992;
        if (isMobile !== this.state.isMobile) this.setState({ isMobile: isMobile });
    };

    renderOrder(order) {
        if (order) {
            return (
                <>
                    <div style={{ textAlign: 'center', maxWidth: '400px', margin: 'auto' }}>
                        Thanks for your order.
                        <br />
                        The status of your order is <strong>{order.status}</strong>.
                        <p style={{ marginTop: '20px' }}>. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .</p>
                        {order.lines.map(function (item, i) {
                            return (
                                <div key={i} style={{ marginBottom: '7px' }}>
                                    {item.quantity} x {item.name}{' '}
                                    <Tag>
                                        <small>
                                            <em>{item.totalAmount.value}€</em>
                                        </small>
                                    </Tag>
                                </div>
                            );
                        })}
                        <p style={{ color: '#dddddd' }}>. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .</p>
                        <strong>TOTAL: {order.amount.value}€</strong>
                        <br />
                        {order.checkout && (
                            <Button style={{ margin: '10px 0', width: '60%' }} block={true} type={'primary'} icon={<CheckOutlined />} href={order.checkout}>
                                Pay with Mollie
                            </Button>
                        )}
                        <p style={{ marginBottom: '20px' }}>. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .</p>
                    </div>
                </>
            );
        }
        return null;
    }

    render() {
        const { order } = this.state;

        return (
            <>
                <Seo />
                <div className="plato-logo">
                    <Logo />
                </div>

                {this.renderOrder(order)}
            </>
        );
    }
}

export default Preview;
